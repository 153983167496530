function validateCheckbox(selector) {
    if (selector) {
        if (selector.checked == undefined || !selector.checked) {
            selector.parentElement.classList.add("error");
            selector.parentElement.parentElement.classList.add("error");
            return true;
        } else {
            selector.parentElement.classList.remove("error");
            selector.parentElement.parentElement.classList.remove("error");
        }
    }
    return false;
}

function validateText(selector) {
    if (selector) {
        if (selector.value == undefined || selector.value.length < 2) {
            selector.parentElement.classList.add("error");
            selector.parentElement.parentElement.classList.add("error");
            return true;
        } else {
            selector.parentElement.classList.remove("error");
            selector.parentElement.parentElement.classList.remove("error");
        }
    }
    return false;
}

function validateEmail(selector) {
    if (selector) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (selector.value === undefined || !re.test(selector.value)) {
            selector.parentElement.classList.add("error");
            selector.parentElement.parentElement.classList.add("error");
            return true;
        } else {
            selector.parentElement.classList.remove("error");
            selector.parentElement.parentElement.classList.remove("error");
        }
    }
    return false;
}

function validateSelect(selector) {
    if (selector) {
        if (selector[selector.selectedIndex].value) {
            selector.parentElement.classList.remove("error");
            selector.parentElement.parentElement.classList.remove("error");
        } else {
            selector.parentElement.classList.add("error");
            selector.parentElement.parentElement.classList.add("error");
            return true;
        }
    }
    return false;
}

function eventChange(selector, callback) {
    selector.addEventListener("change", () => callback(selector));
}

function validate(input, type) {
    switch (type) {
        case "text":
            eventChange(input, validateText);
            return validateText(input);
        case "email":
            eventChange(input, validateEmail);
            return validateEmail(input);
        case "checkbox":
            eventChange(input, validateCheckbox);
            return validateCheckbox(input);
        case "select":
            eventChange(input, validateSelect);
            return validateSelect(input);
    }
}

function hasInputError(form, inputs, type) {
    let isError = false;

    form.querySelectorAll(inputs).forEach(input => {
        if (validate(input, type)) isError = true;
    });

    return isError;
}

function hasError(form, formSelectors) {
    let isError = false;

    if (hasInputError(form, formSelectors.text, "text")) isError = true;
    if (hasInputError(form, formSelectors.email, "email")) isError = true;
    if (hasInputError(form, formSelectors.checkbox, "checkbox")) isError = true;
    if (hasInputError(form, formSelectors.select, "select")) isError = true;
    if (hasInputError(form, formSelectors.phone, "text")) isError = true;

    return isError;
}

export default function() {
    const formSelectors = {
        form: '[data-validation="form"]',
        text: '[data-validation="text"]',
        email: '[data-validation="email"]',
        checkbox: '[data-validation="checkbox"]',
        select: '[data-validation="select"]',
        phone: '[data-validation="phone"]'
    };

    document.querySelectorAll(formSelectors.form).forEach(element => {
        const goal = element.dataset.goal || false;

        element.addEventListener(
            "submit",
            ev => {
                ev.preventDefault();

                if (!hasError(element, formSelectors)) {
                    const formData = new FormData(element);

                    if (goal) {
                        ym(53400304, "reachGoal", goal);
                    }

                    // Display the key/value pairs
                    // for (var pair of formData.entries()) {
                    //     console.log(pair[0] + ", " + pair[1]);
                    // }

                    if (element.dataset.isurl == "true") {
                        window.open(formData.get("url"), "_blank");
                    } else if (element.dataset.isdemo == "true") {

                        if (document.getElementById('crmSelectMain')) {
                            var crm = document.getElementById('crmSelectMain').value;

                            if (crm == "0" || crm == "4") {
                                document.getElementById('hiddenCrm').text = document.getElementById('crmSelectMain').options[document.getElementById('crmSelectMain').selectedIndex].text;
                                document.getElementById('hiddenCrm').setAttribute('value', document.getElementById('hiddenCrm').text);
                                // console.log(document.getElementById('crmSelectMain').options[document.getElementById('crmSelectMain').selectedIndex].text);
                            }
    
                            if (crm == "0" || crm == "4") {
                                crm = "0";
                            }
                            
                        } else {
                            var crm = document.getElementById('crmSelect').value;

                            if (crm == "0" || crm == "4") {
                                document.getElementById('hiddenCrm').text = document.getElementById('crmSelect').options[document.getElementById('crmSelect').selectedIndex].text;
                                document.getElementById('hiddenCrm').setAttribute('value', document.getElementById('hiddenCrm').text);
                                // console.log(document.getElementById('crmSelect').options[document.getElementById('crmSelect').selectedIndex].text);
                            }
    
                            if (crm == "0" || crm == "4") {
                                crm = "0";
                            }
                        }

                        document.getElementById('triggerDemo' + crm + '').click();
                        
                    } else {
                        document.getElementsByClassName('modal-presentation')[0].classList.add('showCube');
                        fetch("/api/send-mail", {
                            method: "post",
                            body: formData
                        }).then(res => {
                            element.reset();
                            document.querySelector(".form-result").click();
                            document.getElementsByClassName('modal-presentation')[0].classList.remove('showCube');
                        }).catch(err => {
                            alert('error');
                            document.getElementsByClassName('modal-presentation')[0].classList.remove('showCube');
                        });
                    }
                }
            },
            false
        );
    });
}


var TestFTP = true;
