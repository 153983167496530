const CSS_STYLE = 'linear-gradient(to right, #00A2F8 0%, #00A2F8 '
  + '{value}%, #55565D {value}%, #55565D 100%)'
const MAX_EMAILS = 300000

function createJson(users, contacts, letters, price) { 
  const activeContacts = contacts > 1000000 ? '> 1000000' : contacts
  const message = `Кол-во пользователей: ${users}<br/>
  Активных контактов в базе: ${activeContacts}<br/>
  Количество писем на одного клиента в месяц: ${letters}<br/>
  Итоговая сумма: ${price}`
  
  document.querySelectorAll('[data-count-order]').forEach((element) => {
    element.dataset.id = message
  })
}

function numberFormat (number) {
  return number.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
}

function counterTotal() {
  const countUsers = Number(document.querySelector('[data-count-users]').value)
  const countContacts = Number(document.querySelector('[data-count-contacts]').value) * 1000
  const total = document.querySelector('[data-count-total]')
  const countLetters = Number(document.querySelector('[data-count-letters]').value)

  let emails = countLetters * countContacts * 12
  emails = emails > MAX_EMAILS ? emails : MAX_EMAILS

  const contactPrice = Math.min(countContacts * 0.192, countContacts * 0.018 + 24000,
    countContacts * 0.005 + 54000)
  const result = (countUsers * 35100 + contactPrice * 65 + emails * 11.70 / 1000)
    .toFixed()

  createJson(countUsers, countContacts, countLetters, result)

  total.textContent = numberFormat(result)
}

export default function () {
  document.querySelectorAll('.product-counter__range').forEach((element) => {
    const input = element.querySelector('input')
    const value = element.querySelector('.product-counter__range-value')
    const button = document.querySelector('[data-count-total-wrap]')
    const total = document.querySelector('[data-count-more-wrap]')
    const onePercent = ((input.max - input.min) / 100).toFixed(1)
    const step = input.dataset.step || 1
    const setNewValue = (newValue, isMore = false) => {
      const cssValue = (newValue / onePercent).toFixed(1)
      value.textContent = isMore ?
        '> ' + numberFormat(1000 * step) : numberFormat(input.value * step)
      input.style.background = CSS_STYLE.replace(/{value}/gi, cssValue)
      if (isMore) {
        total.classList.remove('hidden')
        button.classList.add('hidden')
      }
      else {
        total.classList.add('hidden')
        button.classList.remove('hidden')
      }
    }
    setNewValue(input.value)
    counterTotal()
  
    input.addEventListener('input', () => {
      setNewValue(input.value, (input.dataset.countContacts === '' && Number(input.value) > 1000))
      counterTotal()
    });
  });
  
  document.querySelectorAll('.product-counter__number').forEach((element) => {  
    const maxValue = 99
    const minValue = 1
    const input = element.querySelector('input')
    const minus = element.querySelector('.product-counter__number-minus')
    const plus = element.querySelector('.product-counter__number-plus')
  
    input.addEventListener('input', () => {
      input.value = input.value.match(/[0-9]*/)
      counterTotal()
    });
  
    minus.addEventListener('click', () => {
      if (input.value > minValue) {
        input.value = Number(input.value) - 1
        counterTotal()
      }
    });
  
    plus.addEventListener('click', () => {
      if (input.value < maxValue) {
        input.value = Number(input.value) + 1
        counterTotal()
      }
    });
  });
}