import "@babel/polyfill";
import SmoothScroll from "../../node_modules/smoothscroll-for-websites";

import tab from "./tab";
import scrollTop from "./scrollTop";
import formSubmit from "./formSubmit";
import modal from "./modal";
import simpleSlider from "./slider";
import polyfills from "./helpers/polyfills";
import menu from "./menu";
import map from "./map";
import select from "./select";
import sliders from "./sliders";
import animateBlocks from "./animateBlocks";
import calculate from "./calculate";
import fastStartPageScripts from "./fastStartPageScripts";
import { isIE } from "./helpers/devicesAndBrowsers";

window.onload = function() {
    if (isIE) {
        polyfills();
    }
    SmoothScroll({ stepSize: 50, animationTime: 1000 });
    tab();
    scrollTop();
    formSubmit();
    modal();
    simpleSlider();
    menu();
    map();
    select();
    sliders();
    animateBlocks();
    calculate();
    fastStartPageScripts();
    const errorPage = document.querySelector(".error-page");
    const demoPage = document.querySelector(".demo-page");
    if (errorPage || demoPage) {
        document.querySelector(".contact-us").remove();
    }

    if (localStorage.getItem('creatioPush') != 'true') {
        localStorage.setItem('creatioPush', 'false');
    }

    pushCreatio();
    document.querySelectorAll('#creatioPush .pushButton')[0].addEventListener('click', function () {
    localStorage.setItem('creatioPush', 'true');
    document.getElementById('creatioPush').style.display = 'none';
    });
};

document.querySelectorAll(".table__toggle").forEach(element => {
    element.addEventListener("click", () => {
        element.classList.toggle("open");
        document.querySelector(".table__height").classList.toggle("open");
    });
});

const inputOrderForm = document.querySelectorAll(".input-order-form");

document.querySelectorAll(".button[data-id]").forEach(element => {
    element.addEventListener("click", () => {
        inputOrderForm.forEach(input => {
            input.value = element.dataset.id;
        });
    });
});

const imagePopupAddTo = document.querySelector(".image-popup-insert");

document.querySelectorAll(".image-in-popup").forEach(element => {
    element.addEventListener("click", () => {
        const img = imagePopupAddTo.querySelector("img");
        img.remove();
        const newImg = document.createElement("img");
        newImg.src = element.src;
        newImg.alt = "";
        imagePopupAddTo.appendChild(newImg);
    });
});

document.querySelectorAll(".about-page-team__member").forEach(element => {
    element.addEventListener("click", () => {
        const index = [...element.parentElement.parentElement.children].indexOf(element.parentElement);
        const scrollLeft = element.parentNode.offsetWidth * index;

        document.querySelectorAll(".about-page-team__wrap").forEach(e => {

            if (element.parentElement !== e) {
                e.classList.remove("open");
            }
        });

        element.parentNode.classList.toggle("open");

        if (window.innerWidth > 668) {
            document.querySelector(".mastersBoard").classList.remove("last-opened");

            if (index + 1 == document.querySelectorAll(".about-page-team__member").length && element.parentNode.classList.contains("open")) {
                document.querySelector(".mastersBoard").classList.add("last-opened");
            }
        }

        element.parentNode.parentNode.scrollLeft = scrollLeft;
    });
});

document.querySelectorAll(".project-page__link-back").forEach(element => {
    element.addEventListener("click", () => {
        if (document.referrer === "") {
            location.href = element.dataset.prevurl;
        } else {
            history.back();
        }
    });
});


// Ввод только цифр
document.querySelectorAll('[name="phone"]').forEach(element => {
    element.addEventListener("input", () => {
        element.value = element.value.replace(/\D/, "")
    })
})

function pushCreatio() {
    if (localStorage.getItem('creatioPush') != 'true') {
      document.querySelectorAll('#creatioPush')[0].style.display = 'block';
    }
}
