function changeActiveTab() {
  const selectorTabWrap = '.tab-wrapper';
  const selectorTabContent = '.tab-content';
  const selectorTabLink = '.tab-link';
  const classLinkActive = 'active';

  this.closest(selectorTabWrap).querySelectorAll(selectorTabLink).forEach((element) => {
    element.classList.remove(classLinkActive);
  });

  this.classList.add(classLinkActive);

  const indexTab = [...this.parentElement.children].indexOf(this);
  const newActiveTabConent = this.closest(selectorTabWrap).querySelectorAll(selectorTabContent)[indexTab];
  
  this.closest(selectorTabWrap).querySelectorAll(selectorTabContent).forEach((element) => {
    element.classList.add('hidden');
    element.querySelectorAll('.aos-init').forEach((e) => {
      e.classList.remove('aos-animate');
    });
  });

  newActiveTabConent.classList.remove('hidden');
  setTimeout(() => {
    newActiveTabConent.querySelectorAll('.aos-init').forEach((e) => {
      e.classList.add('aos-animate');
    });
  }, 100);
}

export default function () {
  document.querySelectorAll('.tab-link').forEach((element) => {
    element.addEventListener( "click" , changeActiveTab);
  });
}