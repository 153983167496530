const selectorSelect = 'select';

function createElement(tag, className, innerText = null) {
  const element = document.createElement(tag);
  element.className = className;
  if (innerText) element.innerText = innerText;

  return element;
}

export default function () {
  document.querySelectorAll(selectorSelect).forEach((element) => {
    element.classList.add('hidden');
    
    const wrapper = createElement('div', 'select');
    element.parentNode.insertBefore(wrapper, element);
    element.parentNode.removeChild(element);
    wrapper.appendChild(element);

    const selectGap = createElement('div', 'select__gap', element.dataset.default);
    wrapper.appendChild(selectGap);
    
    const selectList = createElement('ul', 'select__list');
    wrapper.appendChild(selectList);

    const toggleDropdown = () => {
      selectGap.classList.toggle('on');
      selectList.classList.toggle('close');
    };
    
    const selectOption = element.querySelectorAll('option');
    for (let i = 0; i < selectOption.length; i++) {
      if (!selectOption[i].value.length) continue;

      const selectItem = createElement('li', 'select__item', selectOption[i].innerText);

      selectItem.dataset.value = selectOption[i].value;
      selectList.appendChild(selectItem);

      selectItem.addEventListener('click', () => {
        element.querySelector(`[value="${selectItem.dataset.value}"]`).selected = true;
        element.dispatchEvent(new Event('change'));
        
        element[0].closest('select').dispatchEvent(new Event('change'));
        // document.querySelectorAll('#crmSelectMain')[0].value = selectItem.dataset.value
        selectGap.innerText = selectItem.innerText;

        toggleDropdown();        
      });
    }

    selectList.classList.add('close');

    selectGap.addEventListener('click', () => toggleDropdown(), false);

  });  
}