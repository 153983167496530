function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

export default function () {
  document.querySelectorAll('.scroll-top').forEach((element) => {
    element.addEventListener( "click" , scrollTop);
  });
}