import { isMobileDevice } from "./helpers/devicesAndBrowsers";

const contactsMap = document.getElementById("contacts-map");

window.initMap = function() {
    const mapStyle = [
        {
            featureType: "all",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [
                {
                    saturation: "-46"
                },
                {
                    color: "#202023"
                },
                {
                    lightness: 40
                }
            ]
        },
        {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
                {
                    visibility: "off"
                },
                {
                    color: "#f60303"
                },
                {
                    lightness: 16
                }
            ]
        },
        {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off"
                }
            ]
        },
        {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#2a2c35"
                },
                {
                    lightness: 20
                }
            ]
        },
        {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
                {
                    color: "#000000"
                },
                {
                    lightness: 17
                },
                {
                    weight: 1.2
                }
            ]
        },
        {
            featureType: "administrative",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "simplified"
                }
            ]
        },
        {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
                {
                    lightness: "0"
                },
                {
                    gamma: "1.00"
                },
                {
                    weight: "0.01"
                },
                {
                    color: "#2a2c35"
                }
            ]
        },
        {
            featureType: "landscape",
            elementType: "geometry.stroke",
            stylers: [
                {
                    color: "#2a2c35"
                },
                {
                    lightness: "18"
                }
            ]
        },
        {
            featureType: "landscape",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "simplified"
                }
            ]
        },
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
                {
                    color: "#1e2025"
                },
                {
                    lightness: "16"
                },
                {
                    gamma: "0.79"
                }
            ]
        },
        {
            featureType: "road",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off"
                },
                {
                    lightness: "-33"
                },
                {
                    gamma: "1.00"
                }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#22242d"
                },
                {
                    lightness: "17"
                },
                {
                    gamma: "0.63"
                }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
                {
                    color: "#000000"
                },
                {
                    lightness: 29
                },
                {
                    weight: 0.2
                }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "simplified"
                }
            ]
        },
        {
            featureType: "road.arterial",
            elementType: "all",
            stylers: [
                {
                    visibility: "simplified"
                }
            ]
        },
        {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
                {
                    color: "#22242d"
                },
                {
                    lightness: 18
                },
                {
                    gamma: "0.63"
                }
            ]
        },
        {
            featureType: "road.local",
            elementType: "all",
            stylers: [
                {
                    visibility: "simplified"
                }
            ]
        },
        {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
                {
                    color: "#212329"
                },
                {
                    lightness: 16
                },
                {
                    gamma: "0.70"
                }
            ]
        },
        {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
                {
                    color: "#2a2c35"
                },
                {
                    lightness: 19
                },
                {
                    gamma: "0.63"
                }
            ]
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                {
                    lightness: "0"
                }
            ]
        },
        {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#22242d"
                }
            ]
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#22242d"
                },
                {
                    lightness: "34"
                }
            ]
        }
    ];
    const center = { lat: 55.654345, lng: 37.55626 },
        pinCoord = { lat: 55.654345, lng: 37.55626 };
    let mapConfig = {
        center: center,
        animation: google.maps.Animation.DROP,
        zoom: 14,
        mapTypeControl: false,
        fullscreenControl: false,
        styles: mapStyle,
        zoomControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_LEFT
        },
        streetViewControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_LEFT
        }
    };

    if (isMobileDevice) {
        mapConfig.zoomControlOptions = {
            position: google.maps.ControlPosition.RIGHT_CENTER
        };
        mapConfig.streetViewControlOptions = {
            position: google.maps.ControlPosition.RIGHT_CENTER
        };
    }

    if (contactsMap) {
        const map = new google.maps.Map(contactsMap, mapConfig);

        const markerInfo = {
            position: pinCoord,
            icon: "/assets/images/icons/map-marker.png"
        };

        const marker = new google.maps.Marker({
            position: markerInfo.position,
            map: map,
            icon: markerInfo.icon
        });

        // marker.addListener('mouseover', function() {
        //   marker.setAnimation(google.maps.Animation.BOUNCE);
        // });

        // marker.addListener('mouseout', function() {
        //   marker.setAnimation(null);
        // });
    }
};

function loadGoogleMaps() {
    let script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key=AIzaSyChQXOS2ZoeMYhLZIfzLOxh-D6-YzVmC9s&callback=initMap&language=ru");
    (document.getElementsByTagName("body")[0] || document.documentElement).appendChild(script);
}

export default function() {
    if (contactsMap) {
        loadGoogleMaps();
    }
}
