export default function() {
    const businessPackBtnChangeState = document.querySelectorAll("[data-actionChangePack]"),
        btnAddMetaData = document.querySelectorAll("[data-addMetaData]");

    //Переключение статуса
    if (businessPackBtnChangeState.length) {
        businessPackBtnChangeState.forEach(btn => {
            btn.addEventListener("click", function() {
                let statesId = this.parentNode.getAttribute("data-state"),
                    parent = this.closest(".service-page__businessPack");

                parent.setAttribute("data-state", statesId);
            });
        });
    }

    if (btnAddMetaData.length) {
        //Уставнока нескольких аттрибутов на элемент
        function setAttributes(el, attrs) {
            for (var key in attrs) {
                el.setAttribute(key, attrs[key]);
            }
        }

        //Добавляем дополнительные инпуты
        (function() {
            const form = document.querySelector('[data-modal-name="order"] form');

            const packageInput = document.createElement("input"),
                typeInput = document.createElement("input");

            setAttributes(packageInput, {
                type: "hidden",
                value: "",
                name: "package"
            });

            setAttributes(typeInput, {
                type: "hidden",
                value: "",
                name: "type"
            });

            form.append(packageInput, typeInput);
        })();

        //Клик который заполняет данные о выбранном пакете
        btnAddMetaData.forEach(btn => {
            btn.addEventListener("click", function() {
                let parent = this.closest(".service-page__businessPack");

                let statesId = parent.getAttribute("data-state"),
                    packageName = parent.getAttribute("data-packageName"),
                    typeInputEl = document.querySelector('[name="type"]'),
                    packageInputEl = document.querySelector('[name="package"]');

                typeInputEl.value = statesId;
                packageInputEl.value = packageName;
                // parent.setAttribute("data-state", statesId);
            });
        });
    }
}
