function initSlider(slider) {
  let isDown = false;
  let startX;
  let scrollLeft;

  const mousedown = (e) => {
    isDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };

  const mouseleave = () => {
    isDown = false;
  };

  const mousemove = (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3;
    slider.scrollLeft = scrollLeft - walk;
  }

  slider.addEventListener('mousedown', mousedown);
  slider.addEventListener('mouseleave', mouseleave);
  slider.addEventListener('mouseup', mouseleave);
  slider.addEventListener('mousemove', mousemove);
};

if (window.innerWidth <= 668) {
  if(document.querySelector(".mastersBoard") !== null) {
    document.querySelector(".mastersBoard").classList.add("simple-slider");
  }
}

export default function () {
  const sliders = document.querySelectorAll('.simple-slider');
  
  sliders.forEach((slider) => {
    initSlider(slider);
  });

  if (window.innerWidth < 577) {
    const mainTabSliders = document.querySelectorAll('.main-tab .tab-links');

    mainTabSliders.forEach((slider) => {
      initSlider(slider);
    });

    const implementationTabSliders = document.querySelectorAll('.implementation .tab-links .row');

    implementationTabSliders.forEach((slider) => {
      initSlider(slider);
    });
  }
}