import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";


export default function () {
  const slider = document.querySelector('.products-page__slider-init');

  if (slider) {
    tns({
      container: slider,
      items: 1,
      slideBy: 'page',
      autoplay: true,
      controlsText: [
        `<svg width="46" height="26" viewBox="0 0 46 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.4235 25L0.999998 13M0.999998 13L13.4235 1M0.999998 13H45" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`, 
        `<svg width="46" height="26" viewBox="0 0 46 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.5765 25L45 13M45 13L32.5765 1M45 13H1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
      ],
      mouseDrag: true,
      loop: true,
      autoplayButtonOutput: false,
    });
  }
}