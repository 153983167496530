let _targettedModal;
const _triggers = document.querySelectorAll("[data-modal-trigger]");
const _dismiss = document.querySelectorAll("[data-modal-dismiss]");
const modalActiveClass = "is-modal-active";
const isBlur = true;
const blurCss = "filter-blur";
const blockToBlur = document.querySelector("body");

function showModal(el) {
    if (document.querySelectorAll("." + modalActiveClass).length > 0) {
        const item = document.querySelectorAll("." + modalActiveClass);
        for (let i = 0; i < item.length; i++) {
            item[i].classList.remove(modalActiveClass);
        }
    }
    _targettedModal = document.querySelector('[data-modal-name="' + el + '"]');
    _targettedModal.classList.add(modalActiveClass);

    if (isBlur) {
        blockToBlur.classList.add(blurCss);
    }
}

function bindEvents(el, callback) {
    for (let i = 0; i < el.length; i++) {
        (function(i) {
            el[i].addEventListener("click", function(event) {
                callback(this, event);
            });
        })(i);
    }
}

function triggerModal() {
    bindEvents(_triggers, function(that) {
        showModal(that.dataset.modalTrigger);
    });
}

function dismissModal() {
    bindEvents(_dismiss, function(that, event) {
        if (event === undefined || event.target.hasAttribute("data-modal-dismiss")) {
            _targettedModal.classList.remove(modalActiveClass);

            if (isBlur) {
                blockToBlur.classList.remove(blurCss);
            }

            document.querySelectorAll('.demoForm input').forEach(function(el, index) {
                el.value = '';
            })
        }
    });
}

function initModal() {
    triggerModal();
    dismissModal();
}

export default function() {
    initModal();
}
